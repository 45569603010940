<template>
  <div ref="zerofile">
    <add-edit-file
      :file-form="fileForm"
      :add-files-to-users="addFilesToUsers"
      :close-modal="closeModal"
      :preview-image="previewImage"
      :featured-image="featuredImage"
      :options="options"
      :loader="loader"
    />
    <b-table
      ref="fileTable"
      :items="userFiles"
      :fields="columns2"
      aria-busy="true"
      class="position-relative"
      responsive
      show-empty
      bordered
      primary-key="id"
    >
      <template #cell(actions)="data">
        <div class="padd">
          <span>
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              @click="viewAddressData(data.item.id)"
            />
          </span>
          <span>
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              @click="goToEditPage(data.item.id)"
            />
          </span>
          <span>
            <feather-icon
              id="popover-button-1"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="deleteFile(data.item.id)"
            />
          </span>
        </div>
      </template>
    </b-table>
    <b-modal
      id="bv-modal-files"
      hide-footer
      centered
    >
      <b-img
        :src="addressData.file"
        width="450"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import AddEditFile from './AddEditFile.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: {
    AddEditFile,
  },
  props: {
    userFiles: {
      type: Array,
      default: () => [],
    },
    columns2: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      addressData: {},
      id: this.$store.state.generalIds.id,
      previewImage: {
        preview: null,
      },
      featuredImage: {
        image: null,
        url: null,
      },
      loader: false,
    }
  },
  setup() {
    const { router } = useRouter()
    const fileableType = () => {
      if (router.currentRoute.name.includes('show-merchant')) {
        return 'merchants'
      }
      return 'users'
    }
    const fileForm = ref({
      fileable_id: router.currentRoute.params.id,
      fileable_type: fileableType(),
      type: '',
      file: '',
    })
    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData, router, fileForm, fileableType,
    }
  },
  methods: {
    closeModal() {
      this.fileForm = {
        fileable_id: '',
        fileable_type: this.fileableType(),
        type: '',
        file: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.previewImage.preview = null
      this.featuredImage.image = null
      this.$bvModal.hide('file-modal')
    },
    async goToEditPage(routeid) {
      if (routeid) {
        this.$store.commit('generalIds/SET_ID', routeid)
        await axios.get(`files/${routeid}`).then(res => {
          if (res.status === 200) {
            this.fileForm = res.data?.data
            this.previewImage.preview = res.data?.data.file
            this.fileForm.fileable_type = this.fileableType()
            this.$bvModal.show('file-modal')
          }
        })
      }
    },
    async viewAddressData(id) {
      if (id) {
        await axios.get(`files/${id}`).then(res => {
          if (res.status === 200) {
            this.addressData = res.data?.data
            this.$bvModal.show('bv-modal-files')
          }
        })
      }
    },
    addFilesToUsers() {
      if (this.$store.state.generalIds.id) {
        this.loader = true
        this.setFormData(this.fileForm)
        this.formData.append('_method', 'PUT')
        axios.post(`files/${this.fileForm.id}`, this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.closeModal()
            this.$parent.$parent.$parent.$parent.getdata()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        this.setFormData(this.fileForm)
        axios.post('files', this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.closeModal()
            this.$parent.$parent.$parent.$parent.getdata()
            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
    deleteFile(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.delete(`files/${id}`).then(() => {
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
            this.$parent.$parent.$parent.$parent.getdata()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.padd span {
  padding: 2px;
}
</style>
