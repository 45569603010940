<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      />
      <b-col
        cols="12"
        md="6"
        class="mb-2"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            :to="{ name: addComponentName, params: { id: $route.params.id } }"
            variant="primary"
          >
            <span class="text-nowrap"> Add New Address </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-table
      ref="addressTable"
      :items="userAddress"
      :fields="columns"
      aria-busy="true"
      class="position-relative"
      responsive
      show-empty
      bordered
      primary-key="id"
    >
      <template #cell(actions)="data">
        <div class="padd">
          <span>
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              @click="viewAddressData(data.item.id)"
            />
          </span>
          <span>
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              @click="goToEditPage(data.item.id)"
            />
          </span>
          <span>
            <feather-icon
              id="popover-button-1"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="deleteAddress(data.item.id)"
            />
          </span>
        </div>
      </template>

      <template #cell(is_default)="data">
        <b-badge
          class="change-status"
          :variant="data.item.is_default === 1 ? 'success' : 'danger'"
          @click="changeDefaultAddress(data.item)"
        >
          {{ resolveDefaultAddress(data.item.is_default) }}
        </b-badge>
      </template>
    </b-table>
    <b-modal
      id="bv-modal-example"
      hide-footer
      centered
    >
      <view-address :address-data="addressData" />
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import ViewAddress from './ViewAddress.vue'

export default {
  components: {
    ViewAddress,
  },
  props: {
    userAddress: {
      type: Array,
      default: () => [],
    },
    addComponentName: {
      type: String,
      default: '',
    },
    editComponentName: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { router } = useRouter()
    const addressData = ref({})
    const columns = [
      { key: 'id', sortable: true },
      { key: 'address_1', label: 'Address1' },
      { key: 'address_2', label: 'Address2' },
      { key: 'country_name', label: 'Country' },
      { key: 'state_name', label: 'State' },
      { key: 'city_name', label: 'City' },
      { key: 'area_name', label: 'Area' },
      { key: 'is_default', label: 'Default' },
      { key: 'actions' },
    ]

    const resolveDefaultAddress = status => {
      if (status === 1) return 'Deafult'

      return 'Not Default'
    }

    return {
      columns,
      router,
      addressData,
      resolveDefaultAddress,
    }
  },
  methods: {
    goToEditPage(routeid) {
      this.router.push({ name: this.editComponentName, params: { id: this.router.currentRoute.params.id, addressid: routeid } })
    },
    async viewAddressData(id) {
      if (id) {
        await axios.get(`addresses/${id}`).then(res => {
          if (res.status === 200) {
            this.addressData = res.data?.data
            this.$bvModal.show('bv-modal-example')
          }
        })
      }
    },
    deleteAddress(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.delete(`addresses/${id}`).then(() => {
            this.$swal(
              'Deleted!',
              'Deleted Successfully.',
              'success',
            )
            this.$refs.fileTable.refresh()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    changeDefaultAddress(data) {
      this.$swal({
        title: 'Are you sure that you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!',
      }).then(result => {
        if (result.isConfirmed) {
          if (data.is_default === 0) {
            // eslint-disable-next-line no-param-reassign
            data.is_default = 1
            // eslint-disable-next-line no-param-reassign
            this.setAddressableType(data)
            axios.put(`addresses/${data.id}`, data).then(res => {
              if (res.status === 200) {
                this.$swal(
                  'Changed!',
                  'Changed Successfully.',
                  'success',
                )
              }
            })
          } else {
            // eslint-disable-next-line no-param-reassign
            data.is_default = 1
            // eslint-disable-next-line no-param-reassign
            this.setAddressableType(data)
            axios.put(`addresses/${data.id}`, data).then(res => {
              if (res.status === 200) {
                this.$swal(
                  'Changed!',
                  'Changed Successfully.',
                  'success',
                )
              }
            })
          }
        }
      })
    },
    setAddressableType(data) {
      if (this.router.currentRoute.path.includes('users/admin/') || this.router.currentRoute.path.includes('users/merchant/') || this.router.currentRoute.path.includes('users/customer/')) {
        // eslint-disable-next-line no-param-reassign
        data.addressable_type = 'users'
      } else if (this.router.currentRoute.name === 'show-merchant-add-address' || this.router.currentRoute.name === 'show-merchant-edit-address') {
        // eslint-disable-next-line no-param-reassign
        data.addressable_type = 'merchants'
      } else {
        // eslint-disable-next-line no-param-reassign
        data.addressable_type = 'branches'
      }
    },
  },
}
</script>

<style scoped>
.padd span {
  padding: 2px;
}
.change-status {
  cursor: pointer;
}
</style>
