<template>
  <div>
    <b-table
      ref="trTable"
      :items="transactions"
      :fields="columns"
      aria-busy="true"
      class="position-relative"
      responsive
      show-empty
      bordered
      primary-key="id"
    >
      <template #cell(client_cashback)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.offer.client_cashback }}{{ resolveCashbackType(data.item.offer.client_cashback_type) }}
        </span>
      </template>
      <template #cell(created_at)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ timeAgo(data.item.created_at) }}
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'

export default {
  setup() {
    const { router } = useRouter()
    const transactions = ref([])

    const columns = [
      { key: 'branch.name', label: 'Branch Name', sortable: true },
      {
        key: 'offer.name',
        label: 'Offer Name',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      {
        key: 'offer.price',
        label: 'Offer Price',
        thClass: 'text-center',
        tdClass: 'text-center',
        sortable: true,
      },
      {
        key: 'client_cashback',
        label: 'Cashback',
        thClass: 'text-center',
        tdClass: 'text-center',
        sortable: true,
      },
      {
        key: 'created_at',
        label: 'Order Date',
        thClass: 'text-center',
        tdClass: 'text-center',
        sortable: true,
      },
    ]

    const resolveCashbackType = method => {
      if (method === 'percentage') return '%'

      return '£'
    }

    return {
      transactions,
      columns,
      resolveCashbackType,
      router,
    }
  },
  mounted() {
    this.getTransactions()
  },
  methods: {
    async getTransactions() {
      try {
        let endPoint = ''
        if (this.router.currentRoute.path.includes('merchants')) {
          endPoint = 'merchants'
        } else if (this.router.currentRoute.path.includes('users')) {
          endPoint = 'users'
        } else {
          endPoint = 'branches'
        }
        const { data } = await axios.get(`${endPoint}/${this.$route.params.id}/orders`)
        this.transactions = data.data
      } catch (error) {
        // console.log(error)
      }
    },
    // eslint-disable-next-line consistent-return
    timeAgo(input) {
      const date = (input instanceof Date) ? input : new Date(input)
      const formatter = new Intl.RelativeTimeFormat('en')
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      }
      const secondsElapsed = (date.getTime() - Date.now()) / 1000
      // eslint-disable-next-line no-restricted-syntax
      for (const key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key]
          return formatter.format(Math.round(delta), key)
        }
      }
    },
  },

}
</script>

<style>

</style>
