<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      />
      <b-col
        cols="12"
        md="6"
        class="mb-2"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="$bvModal.show('file-modal')"
          >
            <span class="text-nowrap"> Add New File </span>
          </b-button>
          <b-modal
            id="file-modal"
            hide-footer
            centered
          >
            <validation-observer
              ref="infoRules"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="File Type"
                    rules="required"
                  >
                    <b-form-group
                      label="File Type"
                    >
                      <b-form-select
                        v-model="fileForm.type"
                        :options="options"
                        :state="getValidationState(validationContext)"
                        value-field="value"
                        text-field="name"
                        class="mb-3"
                        label="select"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    id="fileInput"
                    label="Upload your file"
                  >
                    <b-form-file
                      v-model="featuredImage.image"
                      :state="Boolean(featuredImage.image)"
                      accept=".jpg, .png"
                      placeholder="select file"
                      @change="onChange"
                    />
                    <template v-if="previewImage.preview">
                      <img
                        :src="previewImage.preview"
                        class="img-fluid mt-3"
                      >
                    </template>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-50"
                >
                  <b-button
                    v-if="!loader"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    @click="addFilesToUsers"
                  >
                    Add
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="closeModal"
                    @click="closeModal()"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    v-if="loader"
                    variant="primary"
                    disabled
                    class="mr-1"
                  >
                    <b-spinner
                      small
                      type="grow"
                    />
                    Loading...
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-modal>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  props: {
    fileForm: {
      type: Object,
      default: () => {},
    },
    addFilesToUsers: {
      type: Function,
      default: () => null,
    },
    closeModal: {
      type: Function,
      default: () => null,
    },
    previewImage: {
      type: Object,
      default: () => {},
    },
    featuredImage: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    loader: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      formData,
      setFormData,
    }
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.fileForm.file = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.previewImage.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
  },
}
</script>

<style>

</style>
