<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <div class="contact-box center-version">
        <div class="topp">
          <div class="user-info">
            <ul>
              <li
                class="cursor-pointer"
                @click="gotoMap()"
              >
                <feather-icon
                  icon="MapPinIcon"
                /> {{ addressData.address_1 }}
              </li>
              <li>
                <feather-icon
                  icon="MapPinIcon"
                /> {{ addressData.address_2 }}
              </li>
              <li>
                <feather-icon
                  icon="GlobeIcon"
                /> {{ addressData.country_name }} - {{ addressData.state_name }} - {{ addressData.city_name }}
              </li>
            </ul>
          </div>
          <div class="contact-box-footer">
            <div class="m-t-xs btn-group">
              <ul>
                <li> <strong>Building Number: </strong>{{ addressData.building_no }} </li>
                <li> <strong>Floor Number: </strong>{{ addressData.floor_no }} </li>
                <li> <strong>Flat Number: </strong>{{ addressData.flat_no }} </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    addressData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    gotoMap() {
      window.location.href = `https://www.google.com/maps/search/?api=1&query=${this.addressData.latitude},${this.addressData.longitude}`
    },
  },
}
</script>

<style>

</style>
